export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "dynamaiweb.firebaseapp.com",
    projectId: "dynamaiweb",
    storageBucket: "dynamaiweb.appspot.com",
    messagingSenderId: "69745268008",
    appId: "1:69745268008:web:48dbb44435b78347656c1d",
    measurementId: "G-MEXV2ZM4F8"
};

